import Swiper, { Pagination, SwiperOptions } from 'swiper'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import Cookies from '~/scripts/components/Cookies'

Swiper.use([ Pagination ])

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)

export default class Main {
    readonly namespace!: string
    private heroLoaded: number = 0

    public constructor (namespace: string) {
        this.namespace = namespace.toLowerCase().replace(' ', '-')
    }

    public start (): void {
        this.loadHero()
    }

    private heroReady (): void {
        const timeline = gsap.timeline(
            {
                paused: true,
                onStart: () => this.appReady()
            }
        )

        timeline.addLabel('start')
        timeline.fromTo(
            document.querySelectorAll('.main-brand'),
            {
                x: -100,
                autoAlpha: 0
            },
            {
                x: 0,
                autoAlpha: 1,
                duration: 1,
                ease: 'power2.out'
            }
        )

        timeline.fromTo(
            document.querySelectorAll('.main-navigation li'),
            {
                autoAlpha: 0,
                x: 100
            },
            {
                autoAlpha: 1,
                x: 0,
                duration: 1,
                ease: 'power2.out',
                stagger: 0.1
            },
            'start'
        )

        timeline.fromTo(
            document.querySelectorAll('.hero-item:not(.hero-2) .media'),
            {
                autoAlpha: 0,
                x: -50
            },
            {
                autoAlpha: 1,
                x: 0,
                duration: 5,
                ease: 'power2.out',
                stagger: {
                    amount: 0.3,
                    ease: 'power2.inOut'
                }
            },
            'start'
        )

        timeline.fromTo(
            document.querySelector('.hero-2 .media'),
            {
                autoAlpha: 0,
                x: -100
            },
            {
                autoAlpha: 1,
                x: 0,
                duration: 3,
                ease: 'power2.out'
            },
            'start'
        )

        timeline.play()
    }

    private appReady (): void {
        document.body.classList.add('app-ready')

        this.lazyloadMedia()
        this.buildCarousel()
        this.bootstrapAnimations()
        this.attachHandlers()

        new Cookies()
    }

    private loadHero (): void {
        const hero = document.querySelector('.hero')

        if (hero && hero instanceof HTMLElement) {
            this.bootstrapHeroAnimations()
            this.lazyloadMedia('figure[data-type="hero"]', true)
        } else {
            this.appReady()
            this.lazyloadMedia()
        }
    }

    private lazyloadMedia (selector: string = 'figure:not([data-type="hero"])', hero: boolean = false): void {
        const elements = document.querySelectorAll(`${selector} img, ${selector} source`)
        const images = document.querySelectorAll(`${selector} img`)

        images.forEach(
            (element: Element): void => {
                const media = element as HTMLImageElement

                media.addEventListener(
                    'load',
                    (): void => {
                        const parent = media.closest('figure') as HTMLElement

                        if (hero) {
                            ++this.heroLoaded

                            if (this.heroLoaded === 4) {
                                this.heroReady()
                            }
                        } else {
                            parent.classList.remove('loading')
                        }
                    }
                )
            }
        )

        elements.forEach(
            (element: Element): void => {
                const media = element as HTMLElement

                if (media.dataset.src) {
                    media.setAttribute('src', media.dataset.src)
                }

                if (media.dataset.srcset) {
                    media.setAttribute('srcset', media.dataset.srcset)
                }
            }
        )
    }

    private buildCarousel (): void {
        const carouselElements = document.querySelectorAll('.carousel')

        carouselElements.forEach(
            (element: Element): void => {
                const carouselElement = element.querySelector('.swiper-container')
                const paginationElement = element.querySelector('.swiper-pagination')
                const carouselOptions: SwiperOptions = {
                    preloadImages: false,
                    grabCursor: true,
                    threshold: 5,
                    resistanceRatio: 0.4,
                    speed: 1000,
                    watchOverflow: true,
                    setWrapperSize: true,
                    longSwipesMs: 100,
                    longSwipesRatio: 0.1,
                    watchSlidesProgress: true,
                    watchSlidesVisibility: true
                }

                if (paginationElement instanceof HTMLElement) {
                    carouselOptions.pagination = {
                        el: paginationElement,
                        type: 'bullets',
                        clickable: true
                    }
                }

                if (carouselElement instanceof HTMLElement) {
                    const carousel = new Swiper(carouselElement, carouselOptions)
                    const zoomButtons = element.querySelectorAll('.swiper-slide')
                    const zoomClose = element.querySelector('.zoom-close')

                    zoomButtons.forEach(
                        (button: Element): void => {
                            button.addEventListener(
                                'click',
                                (event: Event): void => {
                                    event.preventDefault()

                                    gsap.killTweensOf('.col-w-carousel')
                                    gsap.set(
                                        '.col-w-carousel',
                                        { clearProps: 'all' }
                                    )

                                    element.classList.add('zoom')
                                    document.body.classList.add('zoom-visible')
                                    carousel.update()
                                }
                            )
                        }
                    )

                    if (zoomClose instanceof HTMLElement) {
                        zoomClose.addEventListener(
                            'click',
                            (): void => {
                                document.body.classList.remove('zoom-visible')
                                element.classList.remove('zoom')
                                carousel.update()
                            }
                        )
                    }
                }
            }
        )
    }

    private bootstrapAnimations (): void {
        this.bootstrapHeaderAnimations()
        this.bootstrapSectionsAnimations()
    }

    private bootstrapHeaderAnimations (): void {
        gsap.to(
            '.main-navigation .background-overlay',
            {
                alpha: 1,
                ease: 'power2.out',
                scrollTrigger: {
                    refreshPriority: 2,
                    scrub: true,
                    trigger: '.header-trigger',
                    start: 'top bottom',
                    end: 'top+=300px center'
                }
            }
        )
    }

    private bootstrapSectionsAnimations (): void {
        let direction = 1

        gsap.utils.toArray('.ael').forEach(
            (animated: any): void => {
                if (animated instanceof HTMLElement) {
                    const dir = animated.classList.contains('ael-nodir') || (window.innerWidth < 1024) ? 0 : direction

                    gsap.fromTo(
                        animated.querySelectorAll('[class^=col],  .ael-anim'),
                        {
                            autoAlpha: 0,
                            y: 100,
                            x: dir * 20
                        },
                        {
                            duration: 2,
                            autoAlpha: 1,
                            y: 0,
                            x: 0,
                            clearProps: 'all',
                            ease: 'power2.out',
                            stagger: {
                                amount: 0.4,
                                ease: 'power2.out'
                            },
                            scrollTrigger: {
                                refreshPriority: 2,
                                trigger: animated,
                                start: 'top bottom-=10%'
                            }
                        }
                    )

                    direction = direction === 1 ? -1 : 1
                }
            }
        )
    }

    private bootstrapHeroAnimations (): void {
        const heroElement = document.querySelector('.hero')

        if (heroElement && heroElement instanceof HTMLElement) {
            const heroTimeline = gsap.timeline(
                {
                    scrollTrigger: {
                        trigger: heroElement,
                        scrub: 0.6,
                        refreshPriority: 1,
                        start: 'top top',
                        end: 'top+=50% top-=40%'
                    }
                }
            )

            heroTimeline
                .addLabel('start')
                .to(
                    '.hero-main .hero-media',
                    {
                        y: '-10vh',
                        scale: 1.3,
                        autoAlpha: 0.3,
                        ease: 'power1.inOut'
                    }
                )
                .to(
                    '.hero-2 .hero-media',
                    {
                        y: '-18vh',
                        x: '-4vw',
                        ease: 'power2.out'
                    },
                    'start'
                )
                .to(
                    '.hero-3 .hero-media',
                    {
                        y: '-5vh',
                        x: '2vw',
                        scale: 1.1,
                        ease: 'power2.out'
                    },
                    'start'
                )
                .to(
                    '.hero-4 .hero-media',
                    {
                        y: '-20vh',
                        x: '4vw',
                        ease: 'power2.out'
                    },
                    'start'
                )
        }
    }

    private attachHandlers (): void {
        const toggleMenuButton = document.querySelector('.toggle-menu')
        const anchorNav = document.querySelectorAll('.anchor-nav')

        if (toggleMenuButton && toggleMenuButton instanceof HTMLElement) {
            toggleMenuButton.addEventListener(
                'click',
                () => {
                    document.body.classList.toggle('menu-visible')
                }
            )
        }

        anchorNav.forEach(
            (anchor: Element): void => {
                const element = anchor as HTMLAnchorElement

                element.addEventListener(
                    'click',
                    (event: Event): void => {
                        const targetHash = element.getAttribute('href')
                        const targetElement = targetHash ? document.querySelector(targetHash) : false

                        if (targetElement instanceof HTMLElement) {
                            event.preventDefault()

                            document.body.classList.remove('menu-visible')

                            gsap.to(
                                window,
                                {
                                    duration: 1,
                                    overwrite: true,
                                    ease: 'power2.inOut',
                                    scrollTo: {
                                        y: targetElement,
                                        offsetY: window.innerWidth < 1024 ? 0 : 50
                                    }
                                }
                            )
                        }
                    }
                )
            }
        )
    }
}
